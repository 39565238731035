import React from 'react';
import { Route, Switch, HashRouter, Redirect } from 'react-router-dom';
import { HomePage } from './pages/home';
import { ImportList } from './pages/import-list';
import { SingleImport } from './pages/single-import';

function Routes() {
  return (
    <React.Fragment>
      <HashRouter>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact
            path="/invoices/:importId?/:status?"
            component={ImportList} />
          <Route
            path="/import-detail/:filename"
            component={SingleImport}
          />
          <Redirect from="*" to="/" />
        </Switch>
      </HashRouter>
    </React.Fragment>
  );
}

export default Routes;
