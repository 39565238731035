import './import-list.css';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Page,
  PageHeader,
  Button,
  SplitView,
  List,
  ListColumns,
  ListColumn,
  Checkbox,
  ListRow,
  ListCell,
  ListButtonCell,
  ListButtonColumn,
  SimpleDropdown,
  FormControl,
  PaginationView,
  showNotificationBanner,
  renderDetached,
} from '@revisohq/react-components';
import * as ICONS from '@revisohq/react-components/icons';
import {
  IMPORT_STATUS_ERROR,
  IMPORT_STATUS_ALL,
  IMPORT_STATUS_NEW,
  IMPORT_STATUS_IMPORTING,
  IMPORT_STATUS_IMPORTED
} from '../utils/constants';
import { Spinner } from '../components/loader/loaders';
import conf from '../store/shared-configuration';
import {
  DeepClone,
  getFormattedDate
} from '../utils/common';
import texts from '../utils/texts';
import { getInvoices, getFilters, beginImport, deleteInvoices } from '../api/xml';
import { ErrorDetails } from '../components/import-list/error-details';
import { InvoiceInfo } from '../components/import-list/invoice-info';
import { WarningDialog } from '../components/warning-dialog/warning-dialog';

const columnConfig = {
  selection: { width: '2%' },
  status: { width: '5%' },
  statusDate: { width: '7%' },
  importTimestamp: { width: '7%' },
  invoiceType: { width: '5%', growFactor: 2 },
  invoiceNumber: { width: '6%', growFactor: 3 },
  invoiceDate: { width: '7%' },
  seriesNumber: { width: '8%' },
  importId: { width: '13%' },
  entityName: { width: '8%', growFactor: 1 },
  buttons: { width: '17%' },
};

export function ImportList() {
  const history = useHistory();
  const { importId = 'ALL', status = `${IMPORT_STATUS_ALL}` } = useParams();

  const [allInvoices, setAllInvoices] = useState([]);
  const [paginatedInvoices, setPaginatedInvoices] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filters, setFilters] = useState({
    status: isNaN(parseInt(status, 10)) ? IMPORT_STATUS_ALL : parseInt(status, 10),
    importId: importId,
    invoiceNumber: '',
    entityName: '',
  });
  const [filterValues, setFilterVaules] = useState({
    status: [],
    importId: [],
    invoiceNumber: [],
    entityName: [],
  });

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [resetValidationReport, setResetValidationReport] = useState(false);

  const [errorDetails, setErrorDetails] = useState({
    isOpen: false,
    title: texts('INVOICE_ERROR_DETAILS'),
    onClose: () => { setErrorDetails({ ...errorDetails, isOpen: false, errors: [] }) },
    errors: []
  });
  const [invoiceInfo, setInvoiceInfo] = useState({
    isOpen: false,
    title: texts('INVOICE_INFO_DETAILS'),
    onClose: () => { setInvoiceInfo({ ...invoiceInfo, isOpen: false, errors: [] }) },
    data: []
  });

  useEffect(() => {
    (async () => {
      if (conf?.value === 'ERROR') {
        showNotificationBanner(
          { isOpen: true, text: texts('ERROR_LOADING_APP'), type: 'error' },
          { autoCloseTimeout: null },
        );
      } else
        await loadFilterValues();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setFilters({
        ...filters,
        importId,
        status: isNaN(parseInt(status, 10)) ? IMPORT_STATUS_ALL : parseInt(status, 10)
      });
      await loadInvoices(importId, status);
    })();
  }, [importId, status]);

  useEffect(() => {
    getPaginatedInvoices();
  }, [allInvoices, page, pageSize]);

  const loadInvoices = async (importId, status) => {
    setIsLoading(true);
    const invoices = await getInvoices(
      importId === 'ALL' ? undefined : importId,
      isNaN(parseInt(status, 10)) ? IMPORT_STATUS_ALL : status
    );
    let invoicesList = invoices?.map((o) => {
      return { ...o, isChecked: false };
    }) ?? [];
    setAllInvoices(invoicesList);
    setIsLoading(false);
  };

  const loadFilterValues = async () => {
    const filtersFromBackend = await getFilters();
    setFilterVaules({
      ...filterValues,
      status: [
        { id: IMPORT_STATUS_ALL, text: texts('ALL') },
        { id: IMPORT_STATUS_NEW, text: texts('IMPORT_STATUS_0') },
        { id: IMPORT_STATUS_IMPORTING, text: texts('IMPORT_STATUS_1') },
        { id: IMPORT_STATUS_ERROR, text: texts('IMPORT_STATUS_2') },
        { id: IMPORT_STATUS_IMPORTED, text: texts('IMPORT_STATUS_3') },
      ],
      importId: [
        { id: 'ALL', text: texts('ALL') },
        ...filtersFromBackend?.importId ?? []
      ]
    })
  }

  const openErrorDetails = (errors) => {
    setErrorDetails({
      ...errorDetails,
      isOpen: true,
      errors
    });
  }

  const openInvoiceInfo = (data) => {
    setInvoiceInfo({
      ...invoiceInfo,
      isOpen: true,
      data
    });
  }

  const importDocuments = (docs) => {
    setIsLoading(true);
    const elements = docs
      .filter(x => x.status === IMPORT_STATUS_NEW && (!x.errorCodes || x.errorCodes.length === 0))
      .map(x => {
        return {
          importId: x.importId,
          originalFilename: x.originalFilename,
          filename: x.filename
        }
      });
    beginImport(elements).then(res => {
      setIsLoading(false);
      setSelectAll(false);
      setFilters({ ...filters, status: IMPORT_STATUS_ALL });
      loadInvoices(filters.importId, IMPORT_STATUS_ALL);
    });
  }

  const confirmDeleteDocuments = (docs) => {
    renderDetached((onClose) => (
      <WarningDialog
        icon={<ICONS.TrashAltIcon />}
        title={texts('DELETE')}
        abortButtonText={texts('NO')}
        continueButtonText={texts('YES')}
        bodyMessage={texts('ARE_YOU_SURE_DELETE_COUNT', {
          COUNT: docs.length,
        })}
        onClose={onClose}
        onContinue={() => deleteDocuments(docs)}
      />
    ));
  }
  const deleteDocuments = (docs) => {
    setIsLoading(true);
    deleteInvoices(docs
      .filter(x => x.status !== IMPORT_STATUS_IMPORTED || x.status !== IMPORT_STATUS_IMPORTING)
      .map(x => x.filename)).then(res => {
        if (res.resetValidationReport) {
          setResetValidationReport(true);
        }
        setIsLoading(false);
        setSelectAll(false);
        loadInvoices(filters.importId, filters.status);
      });
  }

  const selectAllInvoicesHandler = (checked) => {
    let invoicesList = paginatedInvoices.map((o) => {
      return { ...o, isChecked: checked };
    });
    setSelectAll(checked);
    setPaginatedInvoices(DeepClone(invoicesList));
  };

  const checkInvoiceHandler = (ind, checked) => {
    let temporaryInvoicesList = paginatedInvoices;
    temporaryInvoicesList[ind].isChecked = checked;
    setPaginatedInvoices(DeepClone(temporaryInvoicesList));
  };

  const getPagination = () => {
    const currentPage = page;
    const results = allInvoices.length;
    const lastPageNumber = Math.ceil(results / pageSize);
    return {
      skipPages: currentPage - 1,
      pageSize,
      results,
      firstPage: 1,
      lastPage: lastPageNumber,
      nextPage: currentPage === lastPageNumber ? null : currentPage + 1,
      previousPage: currentPage === 1 ? null : currentPage - 1,
    };
  };

  const getPaginatedInvoices = () => {
    if (!allInvoices || allInvoices.length === 0) {
      setPaginatedInvoices([]);
    } else {
      let skip = pageSize * (page - 1);
      let remaining = allInvoices.length - skip;
      if (remaining < pageSize) {
        setPaginatedInvoices(allInvoices.slice(skip, skip + remaining));
      } else {
        setPaginatedInvoices(allInvoices.slice(skip, skip + pageSize));
      }
    }
  };

  const getStatusTextColor = (statusDescription) => {
    if (statusDescription === IMPORT_STATUS_ERROR) {
      return 'c-red';
    } else if (statusDescription === IMPORT_STATUS_IMPORTED) {
      return 'c-green';
    } else return '';
  };

  return (
    <div class="p-10 bgc-w" style={{ height: '100vh', minWidth: 'fit-content' }}>
      {isLoading && <Spinner />}
      <Page>
        <>
          <div className="back-to-upload"
            onClick={() => history.push(`/?resetReport=true`)} >
            <div style={{ marginTop: '2px' }}><ICONS.AngleLeftIcon /></div>
            {' ' + texts('BACK_TO_UPLOAD')}
          </div>
          <PageHeader title={texts('IMPORT_TITLE')} isSticky={true}>
            {<>
              <SplitView style={{ paddingBottom: '10px' }}>
                <SplitView.Left style={{ alignSelf: 'center' }}>
                  <Button type="primary" text={texts('IMPORT_SELECTED')}
                    onClick={() => importDocuments(paginatedInvoices.filter(x => x.isChecked))} />
                  <Button type="secondary" text={texts('DELETE_SELECTED')}
                    onClick={() => confirmDeleteDocuments(paginatedInvoices.filter(x => x.isChecked))} />
                </SplitView.Left>
                <SplitView.Right align="right" style={{ display: 'flex' }}>
                  <Button
                    type="secondary" text={texts('UPDATE')}
                    iconOnly icon={<ICONS.SyncAltIcon />}
                    style={{ alignSelf: 'center', marginTop: '6px' }}
                    onClick={() => loadInvoices(filters.importId, filters.status)}
                  />
                  <FormControl text={texts('STATUS')} style={{ width: '180px' }}>
                    <SimpleDropdown
                      onChangeValue={(value) => {
                        setFilters({ ...filters, status: value });
                        loadInvoices(filters.importId, value);
                      }}
                      value={filters.status}
                      values={filterValues.status} />
                  </FormControl>
                  <FormControl text={texts('IMPORT_ID')} style={{ width: '250px' }}>
                    <SimpleDropdown
                      onChangeValue={(value) => {
                        setFilters({ ...filters, importId: value });
                        loadInvoices(value, filters.status);
                      }}
                      value={filters.importId}
                      values={filterValues.importId} />
                  </FormControl>
                </SplitView.Right>
              </SplitView>
            </>}
          </PageHeader>
          <List style={{maxHeight: 'calc(100vh - 260px)', overflowY: 'auto'}}>
            <ListColumns stickyTop={'0px'}>
              <ListColumn {...columnConfig.selection}>
                <Checkbox
                  checked={selectAll}
                  style={{ alignSelf: 'center' }}
                  onChangeChecked={(checked) => selectAllInvoicesHandler(checked)}
                />
              </ListColumn>
              <ListColumn className="bold c-label" {...columnConfig.status}>
                {texts('STATUS')}
              </ListColumn>
              <ListColumn className="bold c-label" {...columnConfig.statusDate}>
                {texts('STATUS_DATE')}
              </ListColumn>
              <ListColumn className="bold c-label" {...columnConfig.importTimestamp}>
                {texts('CREATION_DATE')}
              </ListColumn>
              <ListColumn className="bold c-label" {...columnConfig.invoiceType}>
                {texts('DOC_TYPE')}
              </ListColumn>
              <ListColumn className="bold c-label" {...columnConfig.invoiceNumber}>
                {texts('DOC_NO')}
              </ListColumn>
              <ListColumn className="bold c-label" {...columnConfig.invoiceDate}>
                {texts('DOC_DATE')}
              </ListColumn>
              <ListColumn className="bold c-label" {...columnConfig.seriesNumber}>
                {texts('SERIES_NUMBER')}
              </ListColumn>
              <ListColumn className="bold c-label" {...columnConfig.importId}>
                {texts('IMPORT_ID')}
              </ListColumn>
              <ListColumn className="bold c-label" {...columnConfig.entityName}>
                {texts('CUSTOMER_SUPPLIER')}
              </ListColumn>
              <ListButtonColumn {...columnConfig.buttons} />
            </ListColumns>
            {paginatedInvoices?.length > 0 ? paginatedInvoices.map((x, idx) => {
              return (
                <React.Fragment key={idx}>
                  <ListRow>
                    <ListCell {...columnConfig.selection}>
                      <Checkbox
                        checked={x.isChecked}
                        onChangeChecked={(checked) => checkInvoiceHandler(idx, checked)}
                      />
                    </ListCell>
                    <ListCell
                      {...columnConfig.status}
                      className={getStatusTextColor(x.status)}>
                      {texts(`IMPORT_STATUS_${x.status}`)}
                    </ListCell>
                    <ListCell {...columnConfig.statusDate}>{getFormattedDate(x.statusDate)}</ListCell>
                    <ListCell {...columnConfig.importTimestamp}>
                      {getFormattedDate(x.importTimestamp)}
                    </ListCell>
                    <ListCell {...columnConfig.invoiceType}>
                      {x.status === IMPORT_STATUS_ERROR
                        ? ''
                        : (x.documentType ? `${x.documentType} - ` :  '') +
                          texts(`INVOICE_TYPE_${x.invoiceType}`)}
                    </ListCell>
                    <ListCell {...columnConfig.invoiceNumber}>{x.invoiceNumber}</ListCell>
                    <ListCell {...columnConfig.invoiceDate}>{getFormattedDate(x.invoiceDate)}</ListCell>
                    <ListCell {...columnConfig.seriesNumber}>{x.seriesNumber}</ListCell>
                    <ListCell {...columnConfig.importId}
                      title={x.importId ?? ''}>
                      {x.importId}
                    </ListCell>
                    <ListCell {...columnConfig.entityName}
                      title={x.entityName ?? ''}>
                      {x.entityName}
                    </ListCell>
                    <ListButtonCell {...columnConfig.buttons}>
                      {x.status === IMPORT_STATUS_ERROR && x.errorCodes?.length > 0 &&
                        <div className="button-error">
                          <Button text={texts("SHOW_ERRORS")} type="list-entry-action"
                            iconOnly icon={<ICONS.ExclamationCircleIcon type="solid" />}
                            onClick={() => openErrorDetails(x.errorCodes)}
                          />
                        </div>}
                      <Button text={texts('IMPORT')}
                        type="list-entry-action" iconOnly icon={<ICONS.FileImportIcon />}
                        disabled={x.status !== IMPORT_STATUS_NEW}
                        onClick={() => importDocuments([x])}
                      />
                      <Button text={texts('VIEW')}
                        type="list-entry-action" iconOnly icon={<ICONS.EyeIcon />}
                        disabled={x.status === IMPORT_STATUS_ERROR}
                        onClick={() => history.push(`/import-detail/${encodeURI(x.filename.replace(/\//g, '<SLASH>'))}`)}
                      />
                      <Button text={texts('INFO')}
                        type="list-entry-action" iconOnly icon={<ICONS.InfoCircleIcon />}
                        onClick={() => openInvoiceInfo(x)}
                      />
                      <Button text={texts('DELETE')}
                        type="list-entry-action" iconOnly icon={<ICONS.TrashAltIcon />}
                        disabled={x.status === IMPORT_STATUS_IMPORTED || x.status === IMPORT_STATUS_IMPORTING}
                        onClick={() => { confirmDeleteDocuments([x]) }}
                      />
                    </ListButtonCell>
                  </ListRow>
                </React.Fragment>
              );
            }) : (<div style={{ textAlign: 'center', margin: '15px' }}>
              {texts('NO_INVOICES_FOUND')}
            </div>)}
          </List>
          <PaginationView
            className="mt-20"
            onChangePage={(page) => setPage(page)}
            pagination={getPagination()}
            pageSize={pageSize}
            pageSizes={[10, 20, 100].map((x) => ({ size: x, text: `${x}/p` }))}
            onChangePageSize={(size) => setPageSize(size)}
          />
          <ErrorDetails {...errorDetails} />
          <InvoiceInfo {...invoiceInfo} />
        </>
      </Page>
    </div>
  );
}
