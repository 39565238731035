import axios from 'axios';
import { getHeader } from './headers';
import conf from '../store/shared-configuration';

export async function saveImportConfig(importId, config) {
  try {
    const result = await axios.post(
      `api/configuration?importId=${importId}&agreementId=${conf.value.agreementId}`,
      config,
      { headers: getHeader('POST') }
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getConfigurationChoices() {
  try {
    const result = await axios.get(
      `api/configuration?agreementId=${conf.value.agreementId}`,
      { headers: getHeader('GET') }
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}
