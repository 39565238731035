import React from 'react';
import {
  PopupDialog,
} from '@revisohq/react-components';
import texts from '../../utils/texts';

export function InvoiceInfo(props) {
  const { isOpen, title, onClose, data } = props;

  return isOpen && <div style={{ textAlign: 'left !important' }}>
    <PopupDialog
      title={title}
      style={{ minWidth: '500px', maxWidth: '800px' }}
      onClose={onClose}
      closeButtonText={texts('CLOSE')}
    >
      {data && <div style={{ textAlign: 'left' }}>
        <span className='bold'>{texts('SOURCE_FILE')}:</span> {data?.originalFilename} 
      </div>}
    </PopupDialog>
  </div>

}