import React from 'react';
import '@revisohq/react-components/css/activity-indicator.css';
import { ActivityIndicator } from '@revisohq/react-components';

export function Spinner() {
  return (
    <ActivityIndicator
      type="spinner"
      style={{position: 'fixed', top: '50%', left: '50%', zIndex: 9999 }}
    />
  );
}

export function DotsLoader(props) {
  const { label } = props;
  return (
    <ActivityIndicator
      type="dots"
      label={label}
      style={{
        'align-self': 'center',
        'margin-left': '5px',
        'margin-right': '5px',
      }}
    />
  );
}
