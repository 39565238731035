import axios from 'axios';
import { getHeader } from './headers';
import conf from '../store/shared-configuration';

export async function getHtml(filename) {
  try {
    const result = await axios.post(`api/lynfaconte/fpr2Html`,
      {
        agreementId: conf.value.agreementId, 
        filename,
      },
      { headers: getHeader('POST') }
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getXml(filename) {
  try {
    const result = await axios.post(`api/lynfaconte/xml`,
      {
        agreementId: conf.value.agreementId, 
        filename,
      },
      { headers: getHeader('POST') }
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}