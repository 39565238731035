import './vat-mapping.css';
import React, { useEffect, useState } from 'react';
import {
  SimpleDropdown,
  Popup,
  List,
  ListColumns,
  ListColumn,
  ListRow,
  ListCell,
} from '@revisohq/react-components';
import './advanced-configuration';
import texts from '../../utils/texts';
import { DeepClone } from '../../utils/common';

export function VatRatesMapping(props) {
  const { importConfiguration, setImportConfiguration, isOpen, setIsOpen } = props;

  const [sales, setSales] = useState([]);
  const [purchase, setPurchase] = useState([]);

  const popupStyle = {
    position: 'fixed',
    top: '10%',
    width: '60vw',
    height: '80vh',
    textAlign: 'left',
  };

  useEffect(() => {
    setSales(importConfiguration.salesVatRatesMapping);
    setPurchase(importConfiguration.purchaseVatRatesMapping);
  }, []);

  useEffect(() => {
    setImportConfiguration({ ...importConfiguration, salesVatRatesMapping: sales });
  }, [sales]);

  useEffect(() => {
    setImportConfiguration({ ...importConfiguration, purchaseVatRatesMapping: purchase });
  }, [purchase]);

  return (
    isOpen && (
      <Popup
        modal
        style={popupStyle}
        onClose={() => {
          setIsOpen(false);
        }}>
        <div className="popup-info-content">
          <h2 className="popup-info-title">{texts('VAT_RATES_MAPPING')}</h2>
          <div>
            <h3 className="popup-subtitle">Vendite</h3>
            <div className="scrollable" style={{ maxHeight: '27vh' }}>
              <VatMappingRow
                vatAccounts={importConfiguration.vatAccounts}
                mapping={sales}
                setMapping={setSales}
                importConfiguration={importConfiguration}
              />
            </div>
          </div>
          <div>
            <h3 className="popup-subtitle">Acquisti</h3>
            <div className="scrollable" style={{ maxHeight: '27vh' }}>
              <VatMappingRow
                vatAccounts={importConfiguration.vatAccounts}
                mapping={purchase}
                setMapping={setPurchase}
                importConfiguration={importConfiguration}
              />
            </div>
          </div>
        </div>
      </Popup>
    )
  );
}

function VatMappingRow(props) {
  const { vatAccounts, mapping, setMapping } = props;

  const setField = (index, value, field) => {
    let data = DeepClone(mapping);
    data.find((x) => x.vatRate === index)[field] = value;
    setMapping(data);
  };

  return (
    <List>
      <ListColumns stickyTop={'0px'}>
        <ListColumn width="50%">{texts('VAT_RATES')}</ListColumn>
        <ListColumn width="50%">{texts('REVISO_VAT_CODES')}</ListColumn>
      </ListColumns>
      {mapping.map((element) => {
        return (
          <ListRow>
            <ListCell width="50%">{element.vatRate}</ListCell>
            <ListCell width="50%">
              <SimpleDropdown
                style={{ width: '100%' }}
                onChangeValue={(val) => setField(element.vatRate, val, 'vatCode')}
                value={mapping.find((x) => x.vatRate === element.vatRate).vatCode}
                values={vatAccounts}
              />
            </ListCell>
          </ListRow>
        );
      })}
    </List>
  );
}
