import React from 'react';
import { PopupDialog } from '@revisohq/react-components';

export function WarningDialog(props) {
  const { icon, onClose, onContinue, title, abortButtonText, continueButtonText, bodyMessage } = props;

  return (
    <PopupDialog
      icon={icon}
      title={title}
      children={bodyMessage}
      onClose={onClose}
      closeButtonText={abortButtonText}
      primaryButton={{
        text: continueButtonText,
        onClick: async () => {
          await onContinue();
          onClose();
        }
      }}
    />
  );
}
