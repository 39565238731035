import React, { useState, useEffect } from 'react';
import './upload.css';
import texts from '../../utils/texts';
import { upload } from '../../api/xml';
import { logError } from '../../api/errors';
import { FileUploader } from 'react-drag-drop-files';
import { maxFileSize, allowedFileMIMETypes } from '../../utils/constants';
import { NotificationBanner, Button, SyncAltIcon } from '@revisohq/react-components';
import { Spinner } from '../../components/loader/loaders';

export function UploadButton(props) {
  const {
    onUploadCallback,
    validationInProgress,
    filename,
    importId,
    importConfiguration,
    callReloadValidationStatus,
    disabled,
  } = props;

  const [notification, setNotification] = useState({ isOpen: false, message: '', type: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [currentFilename, setFilename] = useState(filename);
  const [currentImportId, setImportId] = useState(importId);

  const onFileChange = (file) => {
    setFilename(file.name);
    const isTypeAllowed = allowedFileMIMETypes.includes(file.type);
    const isSizeAllowed = file.size < maxFileSize;
    if (!isTypeAllowed)
      setNotification({ isOpen: true, message: texts('FILE_TYPE_NOT_ALLOWED'), type: 'warning' });

    if (!isSizeAllowed)
      setNotification({ isOpen: true, message: texts('FILE_SIZE_NOT_ALLOWED'), type: 'warning' });

    if (isTypeAllowed && isSizeAllowed) uploadFile(file, file.name, importConfiguration);
  };

  const startUpload = (file, name, config) => {
    upload(file, name, config).then((res) => {
      if (res.importId) {
        setImportId(res.importId);
        if (onUploadCallback) onUploadCallback();
        setIsLoading(false);
        setNotification({
          isOpen: true,
          message: texts('FILE_UPLOADED_SUCCESSFULLY', { FILENAME: name }),
          type: 'info',
        });
      } else {
        setIsLoading(false);
        setNotification({
          isOpen: true,
          message: texts('FILE_UPLOADING_ERROR'),
          type: 'error',
        });
      }
    });
  };

  const uploadFile = (file, name, config) => {
    setIsLoading(true);
    try {
      if (file) startUpload(file, name, config);
    } catch (ex) {
      setIsLoading(false);
      setNotification({ isOpen: true, message: texts('FILE_UPLOADING_ERROR'), type: 'error' });
      logError(ex);
    }
  };

  const closeNotification = () => {
    setNotification({ ...notification, isOpen: false });
  };

  useEffect(() => {
    if (!validationInProgress) {
      closeNotification();
    }
  }, [validationInProgress]);

  useEffect(() => {
    setFilename(filename);
  }, [filename]);

  return (
    <div>
      {isLoading && <Spinner />}
      <div class="display-f">
        <div>
          {validationInProgress ? (
            <div className="uploaded-filename">
              <div className="uploaded-text">{currentFilename}</div>
            </div>
          ) : disabled ? (
            <div>
              <div
                style={{ width: '329px', height: '85px', padding: '8px 16px 8px 8px' }}
                className="upload-container-disabled">
                <div className="upload-text-disabled">{texts('DROP_FILE_OR')}</div>
                <div className="upload-btn-disabled">{texts('UPOLAD_FILE')}</div>
              </div>
            </div>
          ) : (
            <FileUploader
              classes="upload-container"
              onDrop={onFileChange}
              onSelect={onFileChange}
              name="file">
              <div className="upload-text">{texts('DROP_FILE_OR')}</div>
              <div className="upload-btn">{texts('UPOLAD_FILE')}</div>
            </FileUploader>
          )}
        </div>
        {validationInProgress && (
          <div className="ml-10 display-f" style={{ alignItems: 'center' }}>
            <Button
              type="secondary"
              text={texts('UPDATE_VALIDATION_REPORT')}
              iconOnly
              icon={<SyncAltIcon />}
              onClick={callReloadValidationStatus}
            />
            <div className="ml-10">{texts('UPDATE_VALIDATION_REPORT')}</div>
          </div>
        )}
      </div>
      {notification.isOpen && (
        <NotificationBanner
          style={{ marginTop: '10px' }}
          type={notification.type}
          text={notification.message}
          onClose={() => {
            closeNotification();
            callReloadValidationStatus();
          }}
        />
      )}
    </div>
  );
}
