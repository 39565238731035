import React from 'react';

export default function PDFPreview(props) {
  const { pdfFile, pdfName } = props;

  return <div style={{ height: '93%' }}>
    <h3>{pdfName}</h3>
    <object style={{ width: '100%', height: '100%' }} data={pdfFile} type="application/pdf" >
      <embed style={{ width: '100%', height: '100%' }} src={pdfFile} type="application/pdf" />
    </object>
  </div>
}