import { ActivityIndicator } from '@revisohq/react-components';
import React, { useEffect, useState } from 'react';
import { getHtml } from '../../api/lynfaconte';
import texts from '../../utils/texts';

export default function HTMLPreview(props) {
  const { filename, cachedHtml, setCachedHtml } = props;

  const [html, setHtml] = useState(cachedHtml);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!cachedHtml) {
        setLoading(true);
        const data = await getHtml(filename);
        if (data.content) {
          setHtml(data.content);
          setCachedHtml(data.content);
        }
        setLoading(false);
      } else
        setHtml(cachedHtml);
    })();
  }, [filename]);

  return <div className="preview-box">
    {loading ?
      <div className="m-10">
        <ActivityIndicator type="dots" label={texts('LOADING')} />
      </div>
      : (html ?
        <div style={{ width: "100%", height: "100%", marginTop: '10px' }}>
          <iframe width="100%" height="100%" title="xml-preview"
            className="xml-preview" srcDoc={html}>
          </iframe>
        </div> :
        <div className="m-10">
          {texts('NO_HTML_FOUND')}
        </div>
      )}
  </div>
}