import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  Page, PageHeader, Button,
  SplitView, ToggleableSection, List,
  Form, FormColumn, FormPanel,
  NotificationBanner, showNotificationBanner,
} from '@revisohq/react-components';
import './single-import.css';
import conf from '../store/shared-configuration';
import { getInvoiceDetail, beginImport } from '../api/xml';
import { INVOICE_TYPE_PURCHASE } from '../utils/constants';
import texts from '../utils/texts';
import { getFormattedDate } from '../utils/common';
import { extractInvoiceFromData } from '../utils/single-import-helpers';
import { Spinner } from '../components/loader/loaders';
import PDFPreview from '../components/single-import/pdf-preview';
import SummaryLinesHeader from '../components/single-import/lines-header';
import SummaryLinesRows from '../components/single-import/lines-rows';
import DocumentData from '../components/single-import/document-data';
import HTMLPreview from '../components/single-import/html-preview';
import XMLPreview from '../components/single-import/xml-preview';
import { logError } from '../api/errors';

const styles = {
  toggleableSection: {
    marginBottom: '15px',
    maxHeight: '300px',
    overflowY: 'auto'
  },
  left: {
    width: '60%'
  },
  right: {
    width: '40%',
    height: '100%',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  column: {
    flexGrow: 1,
    height: '80vh',
    width: '100%'
  }
};

const columnConfig = {
  description: { width: '20%', growFactor: 1 },
  revisoContraAccount: { width: '15%', growFactor: 2 },
  revisoVatCode: { width: '15%', growFactor: 3 },
  taxRate: { width: '5%' },
  kind: { width: '8%' },
  normativeReference: { width: '8%' },
  vatCollectability: { width: '8%' },
  amount: { width: '8%' },
  tax: { width: '8%' },
  expenses: { width: '8%' },
};

export const SingleImport = () => {
  const { filename } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [revisoData, setRevisoData] = useState({
    vatCodes: [], 
    contraAccounts: []
  });
  const [summaryLines, setSummaryLines] = useState([]);
  const [invoice, setInvoice] = useState(null);
  const [cachedHtml, setCachedHtml] = useState(null);
  const [cachedXml, setCachedXml] = useState(null);
  const [elementToImport, setElementToImport] = useState(null);
  const [notification, setNotification] = useState({ isOpen: false });
  const [importId, setImportId] = useState(null);
  const [disableBtn, setDisableBtn] = useState(false);

  const returnToList = () => {
    setNotification({ isOpen: false });
    history.push('/invoices/' + importId);
  }

  const importElement = () => {
    if (elementToImport) {
      const document = {
        ...elementToImport,
        additionalConfiguration: { summaryLines }
      };
      setDisableBtn(true);
      setIsLoading(true);
      beginImport([document]).then(res => {
        setIsLoading(false);
        setNotification({ isOpen: true });
      }).catch((err) => {
        logError(err);
        setIsLoading(false);
        setDisableBtn(false);
        showNotificationBanner(
          { isOpen: true, text: texts('ERROR_DURING_IMPORT_START'), type: 'error' },
          { autoCloseTimeout: null }
        );
      });
    }
  }

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (conf?.value === 'ERROR') {
        showNotificationBanner(
          { isOpen: true, text: texts('ERROR_LOADING_APP'), type: 'error' },
          { autoCloseTimeout: null }
        );
      } else {
        const data = await getInvoiceDetail(decodeURI(filename).replace(/<SLASH>/g, '/'));
        if (!data?.report?.validation_report) {
          setIsLoading(false);
          return;
        }
        setImportId(data.report.import_id);
        const { vatCodes, invoiceData, contraAccounts } = extractInvoiceFromData(invoice, data);
        setElementToImport({
          importId: data.report.import_id,
          originalFilename: data.report.validation_report.originalFilename,
          filename: data.report.filename
        });
        setRevisoData({ vatCodes, contraAccounts });
        setInvoice(invoiceData);
      }
      setIsLoading(false);
    })();
  }, [filename]);

  return (<>
    {isLoading && <Spinner />}
    <div class="bgc-w" style={{ height: '100vh', overflowY: 'auto' }}>
      {invoice &&
        <Page style={{ height: '100%' }}>
          <PageHeader
            title={`${invoice.document.type !== null
              ? (invoice.document.type === INVOICE_TYPE_PURCHASE ? texts('SUPPLIER_INVOICE') : texts('CUSTOMER_INVOICE'))
              : ''} ${invoice.document.invoiceNumber ?? ''} - ${getFormattedDate(invoice.document.invoiceDate)}`} />
          <SplitView>
            <SplitView.Left style={styles.left} align="top">
              <ToggleableSection
                label={invoice.document.type !== null
                  ? (invoice.document.type === INVOICE_TYPE_PURCHASE ? texts('SUPPLIER_DATA') : texts('CUSTOMER_DATA'))
                  : texts('DATA')}
                style={{ ...styles.toggleableSection, fontSize: '15px' }}>
                <div style={{ padding: '5px', cursor: 'default' }}>
                  {invoice.entity.name && <>{invoice.entity.name}<br /></>}
                  {invoice.entity.address && <>{invoice.entity.address}<br /></>}
                  {invoice.entity.city && <>
                    {invoice.entity.city}{' '}{invoice.entity.province && "(" + invoice.entity.province + ")"} <br />
                  </>}
                  {invoice.entity.zip && <>{invoice.entity.zip}<br /></>}
                  {invoice.entity.country && <>{invoice.entity.country}<br /></>}
                  {invoice.entity.vatNumber ? "P. IVA: " + invoice.entity.vatNumber : ''} {invoice.entity.fiscalCode ? "C.F.: " + invoice.entity.fiscalCode : ''}
                </div>
              </ToggleableSection>
              <ToggleableSection
                label={texts('DOCUMENT_DATA')}
                style={{ ...styles.toggleableSection, fontSize: '15px' }}>
                <DocumentData invoice={invoice} />
              </ToggleableSection>
              {invoice.paymentDetails &&
                <ToggleableSection
                  label={texts('PAYMENT_DETAILS')}
                  style={{ ...styles.toggleableSection, fontSize: '15px' }}>
                  <div style={{ padding: '5px', cursor: 'default' }}>
                    <div>
                      <span>{texts('PAYMENT_CONDITION')}:{' '}</span>
                      <span className="bold">{invoice.paymentDetails.paymentCondition}</span>
                    </div>
                    <div>
                      <span>{texts('PAYMENT_METHOD')}:{' '}</span>
                      <span className="bold">{invoice.paymentDetails.paymentMethod}</span>
                    </div>
                    <div>
                      <span>{texts('TOTAL_DEADLINES')}:{' '}</span>
                      <span className="bold">{invoice.paymentDetails.totalDeadlines}</span>
                    </div>
                  </div>
                </ToggleableSection>}
              <ToggleableSection
                label={texts('SUMMARY_DATA')}
                style={styles.toggleableSection}>
                <List style={{ cursor: 'default' }}>
                  <SummaryLinesHeader columnConfig={columnConfig} />
                  <SummaryLinesRows
                    columnConfig={columnConfig}
                    invoice={invoice}
                    setInvoice={setInvoice}
                    revisoData={revisoData}
                    setSummaryLines={setSummaryLines}
                    disabled={!importElement || !invoice.importable || disableBtn}
                  />
                </List>
              </ToggleableSection>
              <Button type="default" text={texts('GO_BACK')}
                onClick={() => history.push(`/?resetReport=true`)} />
              <Button type="primary" text={texts('IMPORT')}
                disabled={!importElement || !invoice.importable || disableBtn}
                onClick={importElement} />
            </SplitView.Left>
            <SplitView.Right
              style={styles.right}
              align="top">
              <Form isFullWidth={true} style={{ height: '100%' }}>
                <FormPanel id="pdfattachmenttab" tabTitle={texts('PDF_ATTACHMENT')}
                  style={{ height: '100%', width: '100%' }}>
                  <FormColumn style={styles.column}>
                    {invoice.pdf ?
                      <PDFPreview pdfFile={invoice.pdf} pdfName={invoice.pdfName} /> :
                      <div className="m-10">{texts('NO_ATTACHMENT_FOUND')}</div>
                    }
                  </FormColumn>
                </FormPanel>
                <FormPanel id="xmltab" tabTitle="XML">
                  <FormColumn style={styles.column}>
                    <XMLPreview filename={decodeURI(filename).replace(/<SLASH>/g, '/')}
                      cachedXml={cachedXml} setCachedXml={setCachedXml} />
                  </FormColumn>
                </FormPanel>
                <FormPanel id="htmltab" tabTitle={texts('HTML_PREVIEW')}>
                  <FormColumn style={styles.column}>
                    <HTMLPreview filename={decodeURI(filename).replace(/<SLASH>/g, '/')}
                      cachedHtml={cachedHtml} setCachedHtml={setCachedHtml} />
                  </FormColumn>
                </FormPanel>
              </Form>
            </SplitView.Right>
          </SplitView>
        </Page>
      }
      {notification.isOpen && <div className="notification-banner">
      <NotificationBanner
          type="info"
          text={texts('IMPORT_LOADING')}
          actions={[{
            text: texts('GO_TO_LIST'),
            onClick: returnToList
          }]}
          onClose={returnToList}
        />
      </div>}
    </div>
  </>);
};
