
import React from 'react';
import texts from '../../utils/texts';
import { getFormattedDate } from '../../utils/common';

export default function DocumentData(props) {
  const { invoice } = props;

  return <div style={{ padding: '5px', cursor: 'default' }}>
    <div>
      <span>{texts('INVOICE')} n° </span>
      <span className="bold">{invoice.document.invoiceNumber ?? ''}</span>
      <span> {texts('OF')} </span>
      <span className="bold">{getFormattedDate(invoice.document.invoiceDate)}</span>
      <br />
      <span>{texts('TOTAL')}: </span>
      <span className="bold">
        {invoice.document.total}
      </span>
    </div>
    {invoice.document.bookingDate &&
      <div>
        <span>{texts('BOOKING_DATE')}: </span>
        <span className="bold">{invoice.document.bookingDate}</span>
      </div>}
    {invoice.document.vatDate &&
      <div>
        <span>{texts('VAT_DATE')}: </span>
        <span className="bold">{invoice.document.vatDate}</span>
      </div>}
  </div>
}