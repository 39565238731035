import axios from 'axios';
import { getHeader } from './headers';

export async function getInitialConfiguration(skipConfigOverride) {
  const result = await axios.get(
    `api/auth/${skipConfigOverride ? '?skipConfigOverride=true' : ''}`,
    { headers: getHeader('GET') },
  );
  return result.data;
}

export async function getTokenAsync() {
  try {
    const hrefParts = window.location.href.split('?');
    let eat = null;

    if (hrefParts.length === 2) {
      eat = new URLSearchParams(hrefParts[1]).get('embeddedAppToken');
    }

    var res = await fetch(`api/grant/${eat}`, {
      method: 'GET',
    });

    return await res.json();
  } catch (error) {
    return console.log(error);
  }
}
