import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import '../app.css';
import './home.css';
import { UploadButton } from '../components/upload/upload-button';
import { ValidationReport } from '../components/validation-report/validation-report';
import { WarningDialog } from '../components/warning-dialog/warning-dialog';
import { AdvancedConfiguration } from '../components/advanced-configuration/advanced-configuration';
import conf from '../store/shared-configuration';
import {
  Page,
  PageHeader,
  Card,
  Button,
  showNotificationBanner,
  renderDetached,
  ExclamationTriangleIcon,
  TrashAltIcon,
  NotificationBanner,
} from '@revisohq/react-components';
import texts from '../utils/texts';
import { getFileNameFromPath } from '../utils/common';
import { saveImportConfig } from '../api/configuration';
import { beginImportById, deleteImportById } from '../api/xml';
import { Spinner } from '../components/loader/loaders';
import { getInitialConfiguration } from '../api/auth';
import FileSaver from 'file-saver';
import { UPLOAD_STATUS } from '../utils/constants';

const swVersion = '2021.2';

export function HomePage() {
  const [isAppEnabled, setIsAppEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [featurePreview, setFeaturePreview] = useState(false);

  const [importConfiguration, setImportConfiguration] = useState();
  const [activeReport, setActiveReport] = useState();
  const [showValidation, setShowValidation] = useState(false);
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [importInProgress, setImportInProgress] = useState(false);
  const [uploadedFilename, setUploadedFilename] = useState();
  const [disableStartImportBtn, setDisableStartImportBtn] = useState(false);
  const [isImportNotificationOpen, setImportNotificationOpen] = useState(false);
  const [notification, setNotification] = useState();

  const history = useHistory();

  const downloadExampleFile = () => {
    FileSaver.saveAs(
      process.env.PUBLIC_URL + '/resources/import_xml_example.zip',
      'import_xml_example.zip',
    );
  };

  const saveConfigAndBeginImport = async () => {
    setDisableStartImportBtn(true);
    setLoading(true);
    await saveConfig();
    await beginImportById(activeReport.importId);
    setLoading(false);
    setImportInProgress(true);
    setImportNotificationOpen(true);
  };

  const goToList = () => {
    history.push('/invoices/' + activeReport.importId);
  };

  const saveConfig = async () => {
    await saveImportConfig(activeReport?.importId ?? 'DEFAULT', importConfiguration);
  };

  const deleteImport = async () => {
    renderDetached((onClose) => (
      <WarningDialog
        icon={<TrashAltIcon />}
        title={texts('DELETE_FILE')}
        abortButtonText={texts('NO')}
        continueButtonText={texts('YES')}
        bodyMessage={texts('ARE_YOU_SURE_DELETE', {
          FILENAME: getFileNameFromPath(activeReport?.details?.filename),
        })}
        onClose={onClose}
        onContinue={() => {
          setLoading(true);
          deleteImportById(activeReport.importId).then((deleteRes) => {
            if (deleteRes) setActiveReport(null);
            setLoading(false);
          });
        }}
      />
    ));
  };

  const importAndSaveClickHandler = () => {
    if (activeReport?.productsWithNoCode > 0 && importConfiguration?.importProductsWithNoCode) {
      renderDetached((onClose) => (
        <WarningDialog
          icon={<ExclamationTriangleIcon style={{ color: '#b4c7d8' }} />}
          title={texts('WARNING')}
          abortButtonText={texts('CANCEL')}
          continueButtonText={texts('CONTINUE')}
          bodyMessage={texts('PRODUCT_WITH_NO_CODE_WARNING', {
            productWithNoCodeCount: activeReport?.productsWithNoCode || 0,
          })}
          onClose={onClose}
          onContinue={saveConfigAndBeginImport}
        />
      ));
    } else {
      saveConfigAndBeginImport();
    }
  };

  const onUploadCallback = () => {
    setShowValidation(false);
    notification?.close();
    setValidationInProgress(true);
  };

  const reloadValidationStatus = async () => {
    setLoading(true);
    const res = await getInitialConfiguration(true);
    setValidationStatus(res);
    setLoading(false);
  };

  const setValidationStatus = (value) => {
    setActiveReport(value?.activeReport);
    const isValidationCompleted = !!value?.activeReport
      ? [UPLOAD_STATUS.VAL_DONE, UPLOAD_STATUS.VAL_ERROR].find(
          (x) => x === value.activeReport.status,
        )
      : false;
    const loading = value?.activeReport && !isValidationCompleted;
    setValidationInProgress(loading);
    setShowValidation(isValidationCompleted);
    if (value?.activeReport?.details?.filename)
      setUploadedFilename(getFileNameFromPath(value?.activeReport?.details?.filename));
    setImportInProgress(value?.importInProgress);
    const errors =
      value?.activeReport?.validation?.totalFiles ===
      value?.activeReport?.validation?.errors?.length;
    setDisableStartImportBtn(value?.importInProgress || loading || !value?.activeReport || errors);
    if (value?.importConfiguration) setImportConfiguration(value?.importConfiguration);
  };

  const resetReport = new URLSearchParams(useLocation().search).get('resetReport') ?? false;

  useEffect(() => {
    (async () => {
      if (conf?.value === 'ERROR') {
        setNotification(
          showNotificationBanner(
            {
              isOpen: true,
              text: texts('ERROR_LOADING_APP'),
              type: 'error',
            },
            { autoCloseTimeout: null },
          ),
        );
      } else {
        setIsAppEnabled(conf?.value?.isAppEnabled);
        setFeaturePreview(conf?.value?.featurePreview);
        setImportConfiguration(conf?.value?.importConfiguration);
        setValidationStatus(conf?.value);
        if (resetReport) {
          setActiveReport(null);
          await reloadValidationStatus();
        }
        setTimeout(() => {
          if (!conf?.value?.isAppEnabled) {
            setNotification(
              showNotificationBanner(
                {
                  isOpen: true,
                  text: texts('APP_NOT_ENABLED'),
                  type: 'warning',
                },
                { autoCloseTimeout: null },
              ),
            );
          } else if (activeReport?.status === UPLOAD_STATUS.ACTIVE) {
            const filename = getFileNameFromPath(activeReport?.details?.filename);
            setNotification(
              showNotificationBanner(
                {
                  isOpen: true,
                  text: texts('FILE_UPLOADED_SUCCESSFULLY', { FILENAME: filename }),
                  type: 'info',
                },
                { autoCloseTimeout: null },
              ),
            );
          }
        }, 100);
      }
    })();
  }, [resetReport]);

  return (
    <div class="p-10">
      {(isAppEnabled || activeReport) && (
        <>
          {loading && <Spinner />}
          <Page>
            <PageHeader
              title={texts('IMPORT_TITLE')}
              class="title general-background"
              style={{ backgroundColor: '#F1F5F8' }}></PageHeader>
            <div className="sw-version mb-10">{texts('VERSION') + ': ' + swVersion}</div>
            {featurePreview ? (
              <div className="subtitle-section mb-20">
                <b>{texts('PREVIEW_SUBTITLE')}</b>
                <br />
                {texts('PREVIEW_SUBTITLE_TEXT')}
              </div>
            ) : (
              <div className="subtitle-section mb-20">
                Per iniziare ad utilizzare in poco tempo il tuo nuovo abbonamento di Contabilità in
                Cloud, puoi utilizzare questa nuova funzionalità che ti consente di utilizzare i
                file XML delle fatture elettroniche. Puoi così importare con un'unica operazione le
                anagrafiche di clienti e fornitori, le anagrafiche dei prodotti contenuti nelle
                fatture, le fatture di vendita e di acquisto come registrazioni contabili e di
                registrare i relativi pagamenti se la data di scadenza è nel passato. In questa
                prima edizione potrai importare solo il tipo di documento TD01 – Fattura e con
                alcune limitazioni.
                <br />
                <a style={{ color: '#1D8CD3', cursor: 'pointer' }} onClick={downloadExampleFile}>
                  Scarica il file di esempio
                </a>{' '}
                e{' '}
                <a
                  style={{ color: '#1D8CD3' }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://help.reviso.com/it/articles/5747669-importazione-da-fatture-elettroniche">
                  leggi l'approfondimento
                </a>{' '}
                per conoscere in dettaglio le caratteristiche dei documenti che puoi importare.
              </div>
            )}
            <div class="mb-20" style={{ position: 'relative' }}>
              <Card title={'1. ' + texts('ADVANCED_CONFIGURATION')} className="card-section">
                <AdvancedConfiguration
                  importConfiguration={importConfiguration}
                  setImportConfiguration={setImportConfiguration}
                />
              </Card>
            </div>
            <Card
              title={'2. ' + texts('UPLOAD_DESCRIPTION')}
              className="card-section"
              style={{ marginBottom: '20px' }}>
              <UploadButton
                disabled={featurePreview}
                filename={uploadedFilename}
                validationInProgress={validationInProgress}
                importId={activeReport?.importId}
                importConfiguration={importConfiguration}
                callReloadValidationStatus={reloadValidationStatus}
                onUploadCallback={onUploadCallback}
              />
              {showValidation && !featurePreview && (
                <div className="mt-10">
                  <ValidationReport
                    activeReport={activeReport}
                    saveConfig={saveConfig}
                    importInProgress={importInProgress}
                  />
                </div>
              )}
            </Card>
            <div>
              <Button
                type="secondary"
                text={texts('DELETE_FILE')}
                disabled={featurePreview || validationInProgress || !activeReport}
                onClick={deleteImport}
              />{' '}
              <Button
                type="primary"
                disabled={featurePreview || disableStartImportBtn}
                text={texts('IMPORT_AND_SAVE')}
                onClick={importAndSaveClickHandler}
              />
            </div>
          </Page>
          {isImportNotificationOpen && (
            <div className="notification-banner">
              <NotificationBanner
                type="info"
                text={texts('IMPORT_LOADING')}
                actions={[
                  {
                    text: texts('GO_TO_LIST'),
                    onClick: goToList,
                  },
                ]}
                onClose={goToList}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
