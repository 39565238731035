import React, { useEffect, useState } from 'react';
import {
  FormSection,
  FormColumn,
  FormControl,
  SimpleDropdown,
  Button,
  Checkbox
} from '@revisohq/react-components';
import './advanced-configuration';
import texts from '../../utils/texts';
import conf from '../../store/shared-configuration';
import { VatMapping } from './vat-mapping';
import { VatRatesMapping } from './vat-rates-mapping';
import { VatNaturesMapping } from './vat-natures-mapping';
import './advanced-configuration.css';
import { DeepClone } from '../../utils/common';
import { getConfigurationChoices } from '../../api/configuration';
import { logError } from '../../api/errors';
import { DotsLoader } from '../loader/loaders';

export function AdvancedConfiguration(props) {
  const { importConfiguration, setImportConfiguration } = props;

  const [isVatMappingOpen, setIsVatMappingOpen] = useState(false);
  const [isVatRatesMappingOpen, setIsVatRatesMappingOpen] = useState(false);
  const [isVatNaturesMappingOpen, setIsVatNaturesMappingOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [modifyLoading, setModifyLoading] = useState(false);

  const resetAllToDefault = () => {
    setImportConfiguration({
      ...conf.value.defaultConfiguration,
      accounts: importConfiguration.accounts,
      financialAccounts: importConfiguration.financialAccounts,
      customerGroups: importConfiguration.customerGroups,
      productGroups: importConfiguration.productGroups,
      supplierGroups: importConfiguration.supplierGroups,
      vatAccounts: importConfiguration.vatAccounts,
      salesNumberSeries: importConfiguration.salesNumberSeries,
      purchaseNumberSeries: importConfiguration.purchaseNumberSeries,
    });
  }

  const loadConfigurationChoices = () => {
    setModifyLoading(true);
    getConfigurationChoices().then(res => {
      if (res) {
        setImportConfiguration({
          ...importConfiguration,
          accounts: res.accounts,
          financialAccounts: res.financialAccounts,
          customerGroups: res.customerGroups,
          productGroups: res.productGroups,
          supplierGroups: res.supplierGroups,
          vatAccounts: res.vatAccounts,
          salesNumberSeries: res.salesNumberSeries,
          purchaseNumberSeries: res.purchaseNumberSeries,
        });
      }
      setModifyLoading(false);
      setDisabled(false);
    })
    .catch(err => {
      logError(err);
      setModifyLoading(false);
      setDisabled(false);
    });
  }

  useEffect(() => {
    // do nothing
  }, [importConfiguration]);

  return (<div className="adv-configuration">
    <FormSection>
      <FormColumn>
        <FormControl text={texts('CUSTOMER_GROUP')}>
          <SimpleDropdown
            disabled={disabled}
            onChangeValue={(val) => {
              setImportConfiguration(DeepClone({ ...importConfiguration, customerGroupNumber: val }));
            }}
            value={importConfiguration.customerGroupNumber}
            values={importConfiguration.customerGroups}></SimpleDropdown>
        </FormControl>
        <FormControl text={texts('CUSTOMER_CONTRA_ACCOUNT')}>
          <SimpleDropdown
            disabled={disabled}
            onChangeValue={(val) => {
              setImportConfiguration(DeepClone({ ...importConfiguration, customerContraAccountNumber: val }));
            }}
            value={importConfiguration.customerContraAccountNumber}
            values={importConfiguration.accounts}></SimpleDropdown>
        </FormControl>
      </FormColumn>
      <FormColumn>
        <FormControl text={texts('SUPPLIER_GROUP')}>
          <SimpleDropdown
            disabled={disabled}
            onChangeValue={(val) => {
              setImportConfiguration(DeepClone({ ...importConfiguration, supplierGroupNumber: val }));
            }}
            value={importConfiguration.supplierGroupNumber}
            values={importConfiguration.supplierGroups}></SimpleDropdown>
        </FormControl>
        <FormControl text={texts('SUPPLIER_CONTRA_ACCOUNT')}>
          <SimpleDropdown
            disabled={disabled}
            onChangeValue={(val) => {
              setImportConfiguration(DeepClone({ ...importConfiguration, supplierContraAccountNumber: val }));
            }}
            value={importConfiguration.supplierContraAccountNumber}
            values={importConfiguration.accounts}></SimpleDropdown>
        </FormControl>
      </FormColumn>
      <FormColumn>
        <FormControl text={texts('PRODUCT_GROUP')}>
          <SimpleDropdown
            disabled={disabled}
            onChangeValue={(val) => {
              setImportConfiguration(DeepClone({ ...importConfiguration, productGroupNumber: val }));
            }}
            value={importConfiguration.productGroupNumber}
            values={importConfiguration.productGroups}></SimpleDropdown>
        </FormControl>
        <FormControl text={texts('FINANCIAL_ACCOUNT')}>
          <SimpleDropdown
            disabled={disabled}
            onChangeValue={(val) => {
              setImportConfiguration(DeepClone({ ...importConfiguration, financialAccountNumber: val }));
            }}
            value={importConfiguration.financialAccountNumber}
            values={importConfiguration.financialAccounts}></SimpleDropdown>
        </FormControl>
      </FormColumn>
      <FormColumn>
        <FormControl text={texts('SALES_NUMBER_SERIES')}>
          <SimpleDropdown
            disabled={disabled}
            onChangeValue={(val) => {
              setImportConfiguration(DeepClone({ ...importConfiguration, salesNumberSeriesNumber: val }));
            }}
            value={importConfiguration.salesNumberSeriesNumber}
            values={importConfiguration.salesNumberSeries}></SimpleDropdown>
        </FormControl>
        <FormControl text={texts('PURCHASE_NUMBER_SERIES')}>
          <SimpleDropdown
            disabled={disabled}
            onChangeValue={(val) => {
              setImportConfiguration(DeepClone({ ...importConfiguration, purchaseNumberSeriesNumber: val }));
            }}
            value={importConfiguration.purchaseNumberSeriesNumber}
            values={importConfiguration.purchaseNumberSeries}></SimpleDropdown>
        </FormControl>
      </FormColumn>
      <FormColumn>
        <FormControl text={texts('VAT_RATES_MAPPING')}>
          <Button
            className="w-100p"
            disabled={disabled}
            type="secondary" text={texts('CUSTOMIZE')}
            onClick={_ => { setIsVatRatesMappingOpen(true) }} />
          <VatRatesMapping
            isOpen={isVatRatesMappingOpen}
            setIsOpen={setIsVatRatesMappingOpen}
            importConfiguration={importConfiguration}
            setImportConfiguration={setImportConfiguration} />
        </FormControl>
        <FormControl text={texts('VAT_NATURES_MAPPING')}>
          <Button
            className="w-100p"
            disabled={disabled}
            type="secondary" text={texts('CUSTOMIZE')}
            onClick={_ => { setIsVatNaturesMappingOpen(true) }} />
          <VatNaturesMapping
            isOpen={isVatNaturesMappingOpen}
            setIsOpen={setIsVatNaturesMappingOpen}
            importConfiguration={importConfiguration}
            setImportConfiguration={setImportConfiguration} />
        </FormControl>
      </FormColumn>
      <FormColumn>
        <FormControl text='' className='mt-20 bold'>
          <Checkbox checked={importConfiguration.importProducts}
            disabled={disabled}
            onChangeChecked={(checked) =>
              setImportConfiguration(
                DeepClone({
                  ...importConfiguration,
                  importProducts: checked,
                  importProductsWithNoCode: checked
                    ? importConfiguration.importProductsWithNoCode
                    : false,
                }),
              )
            }
            label={texts('IMPORT_PRODUCTS')}
          />
        </FormControl>
        <FormControl text='' className='mt-10 bold'>
          <Checkbox checked={importConfiguration.importProductsWithNoCode}
            disabled={disabled}
            onChangeChecked={checked =>
              setImportConfiguration(
                DeepClone(
                  {
                    ...importConfiguration, 
                    importProductsWithNoCode: checked,
                    importProducts: checked
                      ? true
                      : importConfiguration.importProducts
                  })
                )
            }
            label={texts('IMPORT_PRODUCTS_WITH_NO_CODE')}
          />
        </FormControl>
      </FormColumn>
      {/* <FormColumn>
        <FormControl text={texts('VAT_MAPPING')}>
          <Button
            className="w-100p"
            disabled={disabled}
            type="secondary" text={texts('CUSTOMIZE')}
            onClick={(e) => { setIsVatMappingOpen(true) }} />
          <VatMapping
            isOpen={isVatMappingOpen}
            setIsOpen={setIsVatMappingOpen}
            vatMapping={importConfiguration.vatMapping}
            setVatMapping={(val) => setImportConfiguration(DeepClone({ ...importConfiguration, vatMapping: val }))}
            vatAccounts={importConfiguration.vatAccounts} />
        </FormControl>
      </FormColumn> */}
    </FormSection>
    <div className="mt-10 display-f">
      <Button
        type="secondary" text={texts('RESET_DEFAULT')}
        onClick={(e) => { resetAllToDefault() }}
      />
      <Button
        disabled={modifyLoading}
        type="secondary" text={texts('MODIFY')}
        onClick={(e) => { loadConfigurationChoices() }}
      />
      <div class='ml-10 display-f'>
        {modifyLoading && <DotsLoader label={texts('CONFIGURATION_LOADING')} />}
      </div>
    </div>
  </div>);
}
