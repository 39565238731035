import { configureStore } from '@reduxjs/toolkit';
import agreementReducer from './store/agreement-token';
import configurationReducer from './store/configuration';

export default configureStore({
  reducer: {
    agreement: agreementReducer,
    configuration: configurationReducer,
  },
});
