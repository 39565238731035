import React from 'react';
import { NotificationBanner } from '@revisohq/react-components';
import { getFileNameFromPath } from '../../utils/common';
import texts from '../../utils/texts';
import { useHistory } from 'react-router-dom';
import { IMPORT_STATUS_ALL, IMPORT_STATUS_ERROR, UPLOAD_STATUS } from '../../utils/constants';
import './validation-report.css';

export function ValidationReport(props) {
  const history = useHistory();
  const { activeReport, saveConfig, importInProgress } = props;

  const getInvoiceErrors = () => {
    if (activeReport?.validation?.errors?.length > 0)
      return activeReport.validation.errors.map((m) => {
        return `${m.filename} - ${texts(m.errorCode)}`;
      });
    return [];
  };

  const getInvalidFiles = () => {
    if (activeReport?.validation?.invalidFilenames?.length > 0)
      return activeReport.validation.invalidFilenames.join(', ');
    return '';
  };

  const getErrors = () => {
    if (activeReport?.status === UPLOAD_STATUS.VAL_ERROR) {
      if (activeReport?.details?.errorCode)
        return [texts(activeReport.details.errorCode)]
      return [texts('ERROR_DURING_FILE_UPLOAD')];
    }

    const errors = getInvoiceErrors();

    const invalidInvoices =
      errors?.length > 0
        ? [`${activeReport.validation.invalidInvoices} ${texts('NOT_VALID_INVOICES')}:`, ...errors]
        : [];

    if (activeReport?.validation?.invalidFilenames?.length)
      return [
        `${activeReport.validation.invalidFilenames.length} ${texts(
          'NOT_VALID_DOCUMENTS',
        )}: ${getInvalidFiles()}`,
        ...invalidInvoices,
      ];
    return invalidInvoices;
  };

  const getFileName = () => getFileNameFromPath(activeReport?.details?.filename);

  return (
    <>
      {activeReport?.validation && (
        <>
          {activeReport.validation?.showSuccess && (
            <NotificationBanner
              className="report"
              type="success"
              text={`${texts('SUCCESSFULLY_LOADED', { FILENAME: getFileName() })} ${texts(
                'CLICK_IMPORT_AND_SAVE',
              )}`}
              messages={[
                `${activeReport.validation.validSalesInvoices} ${texts('VALID_SALES_INVOICES')}`,
                `${activeReport.validation.validPurchaseInvoices} ${texts(
                  'VALID_PURCHASE_INVOICES',
                )}`,
                `${activeReport.validation.validSelfInvoices} ${texts('VALID_SELF_INVOICES')}`,
                `${activeReport.validation.newProducts?.length ?? 0} ${texts('NEW_PRODUCTS')}${
                  activeReport.validation.productsWithNoCode > 0
                    ? texts('NEW_PRODUCTS_WITH_NO_CODE', {
                        COUNT: activeReport.validation.productsWithNoCode,
                      })
                    : ''
                }`,
                `${activeReport.validation.newCustomers} ${texts('NEW_CUSTOMERS')}`,
                `${activeReport.validation.newSuppliers} ${texts('NEW_SUPPLIERS')}`,
              ]}
              numberOfLinesToDisplay={100}
              actions={[
                {
                  text: texts('CLICK_HERE_DETAILS_SUCCESS'),
                  onClick: () =>
                    saveConfig().then(() => {
                      if (
                        activeReport?.details?.fileExtension === 'xml' &&
                        !!activeReport?.details?.filename
                      ) {
                        history.push(
                          `/import-detail/${encodeURI(
                            activeReport.details.filename.replace(/\//g, '<SLASH>'),
                          )}`,
                        );
                      } else {
                        history.push(`/invoices/${activeReport.importId}/${IMPORT_STATUS_ALL}`);
                      }
                    }),
                },
              ]}
            />
          )}
          {activeReport.validation?.showError && (
            <NotificationBanner
              className="report"
              type="error"
              text={`${texts('HAD_FOLLOWING_ERRORS', { FILENAME: getFileName() })}:`}
              messages={getErrors()}
              actions={
                activeReport?.status === UPLOAD_STATUS.VAL_ERROR
                  ? []
                  : [
                      {
                        text: texts('CLICK_HERE_DETAILS_ERRORS'),
                        onClick: () =>
                          saveConfig().then(() =>
                            history.push(
                              `/invoices/${activeReport.importId}/${IMPORT_STATUS_ERROR}`,
                            ),
                          ),
                      },
                    ]
              }
            />
          )}
        </>
      )}
    </>
  );
}
