export function DeepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * 
 * @param {Date} date 
 * @param {String} format 
 * @returns 
 */
export function getFormattedDate(date, format = 'it-IT') {
  if (!date) return '';
  switch (format) {
    case 'it-IT':
      const year = date.substring(0, 4);
      const month = date.substring(5, 7);
      const day = date.substring(8, 10);
      return day + '/' + month + '/' + year;
    default:
      return date.substring(0, 10);
  }
}

export function getFileNameFromPath(path) {
  if (path) {
    let splitPath = path.split('/');
    return splitPath[splitPath.length - 1];
  } else {
    return null;
  }
}
