import React from 'react';
import { ListRow, ListCell, SimpleDropdown, TextInput } from '@revisohq/react-components';
import { DeepClone } from '../../utils/common';

export default function LinesRows(props) {
  const { invoice, columnConfig, revisoData, disabled, setInvoice, setSummaryLines } = props;

  const setField = (index, value, field) => {
    let data = DeepClone(invoice);
    data.lines.find((l) => l.id === index)[field] = value;
    setInvoice(data);
    setSummaryLines(data.lines);
  };

  return (
    <>
      {invoice.lines.map((x, idx) => {
        return (
          <React.Fragment key={idx}>
            <ListRow>
              <ListCell {...columnConfig.description} title={x.description ?? ''}>
                <TextInput
                  value={x.description ?? ''}
                  onChangeValue={(val) => setField(x.id, val, 'description')}
                  disabled={disabled}
                  readOnly={disabled}
                />
              </ListCell>
              <ListCell
                {...columnConfig.revisoContraAccount}
                title={
                  revisoData.contraAccounts.find((acc) => acc.id === x.revisoContraAccount)?.text ??
                  ''
                }>
                <SimpleDropdown
                  disabled={disabled}
                  value={x.revisoContraAccount}
                  values={revisoData.contraAccounts}
                  onChangeValue={(val) => setField(x.id, val, 'revisoContraAccount')}
                />
              </ListCell>
              <ListCell
                {...columnConfig.revisoVatCode}
                title={revisoData.vatCodes.find((acc) => acc.id === x.revisoVatCode)?.text ?? ''}>
                <SimpleDropdown
                  disabled={disabled}
                  value={x.revisoVatCode}
                  values={revisoData.vatCodes}
                  onChangeValue={(val) => setField(x.id, val, 'revisoVatCode')}
                />
              </ListCell>
              <ListCell {...columnConfig.taxRate}>{x.taxRate + ' '}%</ListCell>
              <ListCell {...columnConfig.kind}>{x.kind}</ListCell>
              <ListCell {...columnConfig.normativeReference}>{x.normativeReference}</ListCell>
              <ListCell {...columnConfig.vatCollectability}>{x.vatCollectability}</ListCell>
              <ListCell className="ta-r" {...columnConfig.amount}>
                {x.amount}
              </ListCell>
              <ListCell className="ta-r" {...columnConfig.tax}>
                {x.tax}
              </ListCell>
              {/* <ListCell className="ta-r" {...columnConfig.expenses}>
              {x.expenses}
            </ListCell> */}
            </ListRow>
          </React.Fragment>
        );
      })}
    </>
  );
}
