import axios from 'axios';
import { getHeader } from './headers';
import conf from '../store/shared-configuration';

export async function upload(file, name, configuration) {
  try {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    formData.append('agreementId', conf.value.agreementId);
    formData.append('configurationJson', JSON.stringify(configuration));
    const result = await axios.post(`api/xml/upload`, formData, {
      headers: getHeader('POST-FILE'),
    });
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getInvoices(importId = undefined, status = undefined) {
  try {
    let url = `api/xml/invoices?agreementId=${conf.value.agreementId}`;
    if (importId !== undefined && status !== undefined) {
      url += `&importId=${importId}&status=${status}`;
    } else if (importId !== undefined) {
      url += `&importId=${importId}`;
    } else if (status !== undefined) {
      url += `&status=${status}`;
    }
    const result = await axios.get(url, {
      headers: getHeader('GET'),
    });
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getFilters() {
  try {
    let url = `api/xml/invoice-filters?agreementId=${conf.value.agreementId}`;
    const result = await axios.get(url, {
      headers: getHeader('GET'),
    });
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function beginImport(documents) {
  try {
    const result = await axios.post(
      `api/xml/begin-import?agreementId=${conf.value.agreementId}`,
      { documents },
      { headers: getHeader('GET') },
    );
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function beginImportById(importId) {
  try {
    const result = await axios.get(
      `api/xml/begin-import?importId=${importId}&agreementId=${conf.value.agreementId}`,
      { headers: getHeader('GET') },
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function deleteImportById(importId) {
  try {
    const result = await axios.delete(
      `api/xml/import?importId=${importId}&agreementId=${conf.value.agreementId}`,
      { headers: getHeader('DELETE') },
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function deleteInvoices(filenames) {
  try {
    const result = await axios.post(
      `api/xml/delete-invoices?agreementId=${conf.value.agreementId}`,
      filenames,
      { headers: getHeader('POST') },
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}

export async function getInvoiceDetail(filename) {
  try {
    const result = await axios.get(
      `api/xml/detail?filename=${filename}&agreementId=${conf.value.agreementId}`,
      { headers: getHeader('GET') },
    );
    return result.data;
  } catch (error) {
    console.error(error);
    return false;
  }
}
