import { ActivityIndicator } from '@revisohq/react-components';
import React, { useEffect, useState } from 'react';
import { getXml } from '../../api/lynfaconte';
import texts from '../../utils/texts';

const prettifyXml = (sourceXml) => {
  const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
  const xsltDoc = new DOMParser().parseFromString([
    // indent everything
    '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
    '  <xsl:strip-space elements="*"/>',
    '  <xsl:template match="para[content-style][not(text())]">',
    '    <xsl:value-of select="normalize-space(.)"/>',
    '  </xsl:template>',
    '  <xsl:template match="node()|@*">',
    '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
    '  </xsl:template>',
    '  <xsl:output indent="yes"/>',
    '</xsl:stylesheet>',
  ].join('\n'), 'application/xml');

  const xsltProcessor = new XSLTProcessor();
  xsltProcessor.importStylesheet(xsltDoc);
  const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
  const resultXml = new XMLSerializer().serializeToString(resultDoc);
  return resultXml;
};

export default function XMLPreview(props) {
  const { filename, cachedXml, setCachedXml } = props;

  const [xml, setXml] = useState(cachedXml);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!cachedXml) {
        setLoading(true);
        const data = await getXml(filename);
        if (data) {
          const prettified = prettifyXml(data);
          setXml(prettified);
          setCachedXml(prettified);
        }
        setLoading(false);
      } else
        setXml(cachedXml);
    })();
  }, [filename]);

  return <div className="preview-box">
    {loading ?
      <div className="m-10">
        <ActivityIndicator type="dots" label={texts('LOADING')} />
      </div>
      : (xml ?
        <div className="xml-preview">
          <pre className="mt-0">
            {xml}
          </pre>
        </div> :
        <div className="m-10">
          {texts('NO_XML_FOUND')}
        </div>
      )}
  </div>

}