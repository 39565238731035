import translations from './translations';
import configuration from '../store/shared-configuration';

export function getUserLanguage() {
  const userLang =
    configuration?.value?.language ||
    navigator.language ||
    navigator.userLanguage;
  return userLang;
}

export function getUserISOLanguage() {
  const userLang = getUserLanguage();
  let result;
  if (userLang.length === 5 && !translations.fallbacks[userLang])
    result = userLang;
  else
    result = translations.fallbacks[userLang];
  return result === 'en-GL' ? 'en-UK' : result;
}

function getTranslation(textId) {
  const allLanguages = translations.texts[textId];

  if (!allLanguages) {
    return textId;
  }
  const iso = getUserISOLanguage();

  if (iso in allLanguages) {
    return allLanguages[iso];
  } else if (translations.fallbacks[iso]) {
    return allLanguages[translations.fallbacks[iso]];
  } else {
    return allLanguages['en-GL'];
  }
}

const translate = (textId, replacements) => {
  const text = getTranslation(textId);
  if (!replacements) {
    return text;
  }

  return text.replace(/\[[^\[\]]+\]/g, (match) => {
    const withoutBrackets = match.slice(1, -1);
    const replacement = replacements[withoutBrackets];
    return replacement == null ? match : replacement;
  });
};

export default translate;
