import Routes from './routes';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect } from 'react';
import '@revisohq/react-components/css/activity-indicator.css';
import { selectToken, getToken } from './store/agreement-token';
import { DotsLoader } from './components/loader/loaders';
import './app.css';
import { getConfiguration, selectConfiguration } from './store/configuration';

function App() {
  const token = useSelector(selectToken);
  const configuration = useSelector(selectConfiguration);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      dispatch(getToken()).then(() => {
        if (!configuration) {
          dispatch(getConfiguration());
        }
      });
    }
  }, [token]);

  return token && configuration ? (
    <Routes />
  ) : (
    <div className="loading-section">
      <DotsLoader />
    </div>
  );
}

export default App;
