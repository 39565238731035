import React from 'react';
import { ListColumn, ListColumns } from '@revisohq/react-components';
import texts from '../../utils/texts';

export default function SummaryLinesHeader(props) {
  const { columnConfig } = props;

  return <ListColumns stickyTop={true}>
    <ListColumn className="bold c-label word-break" {...columnConfig.description}>
      {texts('DESCRIPTION')}
    </ListColumn>
    <ListColumn className="bold c-label word-break" {...columnConfig.revisoContraAccount}>
      {texts('REVISO_ACCOUNT')}
    </ListColumn>
    <ListColumn className="bold c-label word-break" {...columnConfig.revisoVatCode}>
      {texts('REVISO_VAT_CODE')}
    </ListColumn>
    <ListColumn className="bold c-label display-b" {...columnConfig.taxRate}>
      {texts('TAX_RATE')}
    </ListColumn>
    <ListColumn className="bold c-label display-b" {...columnConfig.kind}>
      {texts('KIND')}
    </ListColumn>
    <ListColumn className="bold c-label" {...columnConfig.normativeReference}>
      {texts('NORMATIVE_REFERENCE')}
    </ListColumn>
    <ListColumn className="bold c-label" {...columnConfig.vatCollectability}>
      {texts('VAT_COLLECTABILITY')}
    </ListColumn>
    <ListColumn className="bold c-label ta-r display-b" {...columnConfig.amount}>
      {texts('AMOUNT')}
    </ListColumn>
    <ListColumn className="bold c-label ta-r display-b" {...columnConfig.tax}>
      {texts('TAX')}
    </ListColumn>
    {/* <ListColumn className="bold c-label ta-r display-b" {...columnConfig.expenses}>
      {texts('EXPENSES')}
    </ListColumn> */}
  </ListColumns>
}