import React from 'react';
import {
  PopupDialog,
} from '@revisohq/react-components';
import texts from '../../utils/texts';

export function ErrorDetails(props) {
  const { isOpen, title, onClose, errors } = props;

  return isOpen && <div style={{ textAlign: 'left !important' }}>
    <PopupDialog
      title={title}
      style={{ minWidth: '500px', maxWidth: '800px' }}
      onClose={onClose}
      closeButtonText={texts('CLOSE')}
    >
      <div style={{ textAlign: 'left' }}>
        <ul>
          {errors.map(err => {
            return <li>{err}: {texts(err)}</li>
          })}
        </ul>
      </div>
    </PopupDialog>
  </div>

}