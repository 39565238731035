import {
  IMPORT_STATUS_ERROR,
  IMPORT_STATUS_IMPORTED,
  IMPORT_STATUS_IMPORTING,
  INVOICE_TYPE_PURCHASE,
  INVOICE_TYPE_SALES,
  INVOICE_TYPE_SELF,
} from '../utils/constants';
import { getFormattedDate } from './common';
import { getUserISOLanguage } from './texts';
const pdfPrefix = 'data:application/pdf;base64,';
const provinceCodes = ["", "AG", "AL", "AN", "AO", "AQ", "AR", "AP", "AT", "AV",
  "BA", "BT", "BL", "BN", "BG", "BI", "BO", "BZ", "BS", "BR", "CA", "CL", "CB", "CI",
  "CE", "CT", "CZ", "CH", "CO", "CS", "CR", "KR", "CN", "EN", "FM", "FE", "FI", "FG",
  "FC", "FR", "GE", "GO", "GR", "IM", "IS", "SP", "LT", "LE", "LC", "LI", "LO", "LU",
  "MC", "MN", "MS", "MT", "VS", "ME", "MI", "MO", "MB", "NA", "NO", "NU", "OG", "OT",
  "OR", "PD", "PA", "PR", "PV", "PG", "PU", "PE", "PC", "PI", "PT", "PN", "PZ", "PO",
  "RG", "RA", "RC", "RE", "RI", "RN", "RM", "RO", "SA", "SS", "SV", "SI", "SR", "SO",
  "TA", "TE", "TR", "TO", "TP", "TN", "TV", "TS", "UD", "VA", "VE", "VB", "VC", "VR",
  "VV", "VI", "VT", "SD"];

const paymentConditions = {
  TP01: 'Pagamento a rate',
  TP02: 'Pagamento completo',
  TP03: 'Anticipo',
};

const paymentMethods = {
  MP01: 'Contanti',
  MP02: 'Assegno',
  MP03: 'Assegno circolare',
  MP04: 'Contanti presso Tesoreria',
  MP05: 'Bonifico',
  MP06: 'Vaglia cambiario',
  MP07: 'Bolletino bancario',
  MP08: 'Carta di pagamento',
  MP09: 'RID',
  MP10: 'RID utenze',
  MP11: 'RID veloce',
  MP12: 'Riba',
  MP13: 'MAV',
  MP14: 'Quietanza erario stato',
  MP15: 'Giroconto su conti di contabilità speciale',
  MP16: 'Domiciliazione bancaria',
  MP17: 'Domiciliazione postale',
  MP18: 'Bollettino di c/c postale',
  MP19: 'SEPA Direct Debit',
  MP20: 'SEPA Direct Debit CORE',
  MP21: 'SEPA Direct Debit B2B',
  MP22: 'Trattenuta su somme già riscosse',
};

const getVatCode = (vatPercentage, vatCodes, isAssosoftware, isPurchase, nature, vatMapping) => {
  let vatCode = '';
  if (vatPercentage === 0 && isAssosoftware) {
    vatCode = vatMapping[nature] ?? (isPurchase ? 'A100' : 'V100');
  } else if (vatPercentage === 0 && !isAssosoftware) {
    const exempts = vatCodes.filter((x) => x.exemptVatCode?.code === nature);
    if (exempts.length > 0) vatCode = exempts[0].vatCode;
    else vatCode = isPurchase ? 'A100' : 'V100';
  } else {
    const contraVatAccount = vatCodes.filter((x) => x.ratePercentage === vatPercentage);
    if (contraVatAccount && contraVatAccount.length > 0) vatCode = contraVatAccount[0].vatCode;
  }
  return vatCode;
};

const getVatCodeFromMapping = (vatPercentage, nature, isPurchase, mappings) => {
  let vatCode = null;
  const natureMapping = isPurchase
    ? mappings.purchaseVatNaturesMapping
    : mappings.salesVatNaturesMapping;
  const vatMapping = isPurchase ? mappings.purchaseVatRatesMapping : mappings.salesVatRatesMapping;
  if (vatPercentage !== 0)
    vatCode = vatMapping.find((x) => x.vatRate === vatPercentage.toString())?.vatCode;
  else vatCode = natureMapping.find((x) => x.vatNature === nature)?.vatCode;
  return vatCode;
};

const getITProvince = (provinceNumber) => {
  const parsed = parseInt(provinceNumber, 10);
  if (isNaN(parsed)) return '';
  return provinceCodes[parsed] ?? '';
};

export const extractInvoiceFromData = (invoice, data) => {
  const validation = data.report.validation_report;
  const entity = validation.isPurchase
    ? validation.revisoSupplier ?? validation.supplier
    : validation.revisoCustomer ?? validation.customer;
  const body = validation.invoiceBody;
  const total = body?.DatiGenerali?.DatiGeneraliDocumento?.ImportoTotaleDocumento;
  const currency = body?.DatiGenerali?.DatiGeneraliDocumento?.Divisa ?? 'EUR';
  const format = new Intl.NumberFormat(getUserISOLanguage(), { style: 'currency', currency });
  const linesDetail = body?.DatiBeniServizi?.DatiRiepilogo
    ? Array.isArray(body.DatiBeniServizi.DatiRiepilogo)
      ? body.DatiBeniServizi.DatiRiepilogo
      : [body.DatiBeniServizi.DatiRiepilogo]
    : [];

  const lines = linesDetail.map((line) => {
    let revisoContraAccount = data.contraAccountNumber;
    // let revisoVatCode = getVatCode(
    //   line.AliquotaIVA,
    //   data.vatCodes,
    //   validation.isAssosoftware,
    //   validation.isPurchase,
    //   line.Natura,
    //   data.vatMapping,
    // );
    let revisoVatCode = getVatCodeFromMapping(
      line.AliquotaIVA,
      line.Natura,
      validation.isPurchase,
      {
        salesVatNaturesMapping: data.salesVatNaturesMapping,
        purchaseVatNaturesMapping: data.purchaseVatNaturesMapping,
        salesVatRatesMapping: data.salesVatRatesMapping,
        purchaseVatRatesMapping: data.purchaseVatRatesMapping,
      },
    );

    const lineId =  `${line.AliquotaIVA ?? '0'}${line.Natura ?? ''}`;
    const customLine = data.additionalConfiguration?.summaryLines?.find(
      (x) => x.id === lineId,
    );
    if (customLine) {
      revisoContraAccount = customLine.revisoContraAccount;
      revisoVatCode = customLine.revisoVatCode;
    }
    return {
      id: lineId,
      description:
        customLine?.description ??
        `${entity.name}, nr. ${validation.invoiceNumber} del ${getFormattedDate(
          validation.invoiceDate,
        )}`,
      taxRate: line.AliquotaIVA,
      amount: format.format(line.ImponibileImporto),
      currency,
      vatCollectability: body?.DatiBeniServizi?.DatiRiepilogo?.EsigibilitaIVA,
      tax: format.format(line.Imposta),
      kind: line.Natura ?? '',
      revisoContraAccount,
      revisoVatCode,
    };
    /* Complete row example: {
      revisoContraAccount: '6305010',
      revisoVatCode: '1',
      taxRate: '22',
      kind: '',
      normativeReference: '',
      vatCollectability: 'Immediata',
      amount: '1716',
      currency: '€',
      tax: '377,52',
      expenses: '',
    }, */
  });
  let pdfAttachment = null;
  if (Array.isArray(body?.Allegati))
    pdfAttachment = body?.Allegati.find((x) => x.FormatoAttachment === 'PDF');
  else pdfAttachment = body?.Allegati;

  const pdf =
    pdfAttachment?.FormatoAttachment === 'PDF' ? pdfPrefix + pdfAttachment.Attachment : null;
  const pdfName = pdfAttachment?.FormatoAttachment === 'PDF' ? pdfAttachment?.NomeAttachment : null;

  const country =
    typeof entity.countryCode === 'string' ? entity.countryCode : entity.countryCode?.code;

  let paymentDetails = null;

  if (body?.DatiPagamento) {
    const total = Array.isArray(body?.DatiPagamento?.DettaglioPagamento)
      ? body.DatiPagamento.DettaglioPagamento.map((x) => x.ImportoPagamento).reduce(
          (a, b) => a + b,
          0,
        )
      : body?.DatiPagamento?.DettaglioPagamento?.ImportoPagamento;

    const method = Array.isArray(body?.DatiPagamento?.DettaglioPagamento)
      ? body.DatiPagamento.DettaglioPagamento[0].ModalitaPagamento
      : body?.DatiPagamento?.DettaglioPagamento?.ModalitaPagamento;

    paymentDetails = {
      paymentCondition: paymentConditions[body?.DatiPagamento?.CondizioniPagamento ?? 'TP01'],
      paymentMethod: paymentMethods[method ?? 'MP01'],
      totalDeadlines: format.format(total ?? 0),
    };
  }

  const invoiceData = {
    ...invoice,
    importable:
      !validation.errorCode &&
      data.importStatus !== IMPORT_STATUS_IMPORTED &&
      data.importStatus !== IMPORT_STATUS_IMPORTING &&
      data.importStatus !== IMPORT_STATUS_ERROR,
    pdf,
    pdfName,
    errorCode: validation.errorCode,
    entity: {
      name: entity.name,
      address: entity.address,
      city: entity.city,
      zip: entity.zip,
      country,
      province:
        entity.provinceCode ??
        (country === 'IT' ? getITProvince(entity.province?.provinceNumber) : ''),
      vatNumber: entity.vatNumber,
      fiscalCode: entity.corporateIdentificationNumber ?? entity.fiscalCode,
    },
    document: {
      invoiceNumber: validation.invoiceNumber,
      invoiceDate: validation.invoiceDate,
      type: validation.isSelfInvoice
        ? INVOICE_TYPE_SELF
        : validation.isPurchase
        ? INVOICE_TYPE_PURCHASE
        : INVOICE_TYPE_SALES,
      total: format.format(total),
      currency,
    },
    paymentDetails,
    lines: lines ?? [],
  };

  return {
    invoiceData,
    vatCodes: data?.vatCodes
      ? data.vatCodes.map((x) => {
          return { id: x.vatCode, text: `${x.vatCode} - ${x.name}` };
        })
      : [],
    contraAccounts: data?.contraAccounts
      ? data.contraAccounts.map((x) => {
          return {
            id: x.accountNumber,
            text: `${x.accountNumber} - ${x.name}`,
          };
        })
      : [],
    summaryLines: data.additionalConfiguration?.summaryLines,
  };
};
