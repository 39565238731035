export const maxFileSize = 100 * 1_048_576;

export const allowedFileMIMETypes = [
  'application/x-zip-compressed',
  'application/x-compressed',
  'application/zip',
  'multipart/x-zip',
  'application/xml',
  'text/xml',
];

export const INVOICE_TYPE_SELF = 0;
export const INVOICE_TYPE_SALES = 1;
export const INVOICE_TYPE_PURCHASE = 2;

export const IMPORT_STATUS_NEW = 0;
export const IMPORT_STATUS_IMPORTING = 1;
export const IMPORT_STATUS_ERROR = 2;
export const IMPORT_STATUS_IMPORTED = 3;
export const IMPORT_STATUS_ALL = 4;

export const UPLOAD_STATUS = {
  ACTIVE: 0,
  VAL_DONE: 1,
  CLOSED: 2,
  VAL_ERROR: 3,
};